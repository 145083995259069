.featherContainer {
  position: relative;
  height: 460px;
  max-width: 100vw;
  overflow: hidden;
}

.featherSecondImg {
  opacity: 0;
  width: 116px !important;
  height: auto;
}

.featherFirstImg {
  opacity: 0;
  width: 104px !important;
  height: auto;
}

.featherFirst {
  position: absolute;
  top: 0;
  left: 16%;
}

.featherFirstShadow {
  position: absolute;
  top: 0;
  left: 16%;
  opacity: 0;
}

.featherSecond {
  position: absolute;
  top: 0;
  left: 47%;
}

.featherSecondShadow {
  position: absolute;
  top: 0;
  left: 47%;
  opacity: 0;
}

/*********************
TABLET & SMALLER LAPTOPS
*********************/
@media only screen and (min-width: 481px) {
  .featherFirst {
    left: 26%;
  }

  .featherFirstShadow {
    left: 26%;
  }

  .featherSecond {
    left: 47%;
  }

  .featherSecondShadow {
    left: 47%;
  }
}

/*********************
DESKTOP
*********************/
@media only screen and (min-width: 1030px) {
  .featherFirst {
    left: 40%;
  }

  .featherFirstShadow {
    left: 40%;
  }

  .featherSecond {
    left: 50%;
  }

  .featherSecondShadow {
    left: 50%;
  }
}
