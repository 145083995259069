@import './_breakpoints.css';
@value border-color-light from './_colors.css';

.page404 {
  max-width: 600px;
  min-width: 300px;
  margin: 0 auto;
  padding: 40px 16px 0;
  min-height: 690px;
  height: calc(100vh - 112px);
}

.FeathersAndSearch {
  display: flex;
  flex-direction: column;
}

.text404 {
  font-weight: 900;
  text-align: center;
  font-size: 20px;
}

.AutoComplete {
  margin: 16px auto;
  max-width: 375px;
}

.InputElement,
.Results {
  border-color: border-color !important;
}

@media (--small-screen) {
  .text404 {
    font-size: 16px;
  }

  .InputElement,
  .AutoComplete {
    max-width: 355px;
  }

  .FeathersAndSearch {
    flex-direction: column-reverse;
  }
}

@media only screen and (min-width: 481px) {
  .breakMobile {
    display: none;
  }
}
